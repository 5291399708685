
import Vue from 'vue';
<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="70%"
      :persistent="((load || comp_caja_id > 0 || compCajaId > 0) && nuevo == 1) || ((comp_caja_id > 0 || compCajaId > 0) && checkLapos == 1 && selectedEstado.estado_id == 1)"
    >
      <v-card
        flat
        :loading="load || loadingCircular"
        :disabled="load"
      >
        <v-card-title>
          <v-icon class="ma-2">far fa-money-bill-alt</v-icon> Efectivo Disponible en Caja {{ format_money(efectivo_disponible) }}
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false" title="Cerrar" v-if="comp_caja_id == 0 && compCajaId == 0"><v-icon>fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <!-- Documento o CUIL, Tipo de Documento y Nombre de Cliente -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-1">
            <v-col cols="12" sm="3" md="3" class="py-0">
              <v-autocomplete
                v-model="selectedTipoDoc"
                item-text="nombre"
                label="Tipo Documento"
                :items="tiposDocumento"
                return-object
                hide-details
                clearable
                outlined
                dense
                @change="controlDNI()"
                :disabled="nuevo == 0"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" md="3" :class="selectedTipoDoc.id == 1 ? 'py-0 d-flex' : 'py-0'">
              <v-text-field
                :id="docRef"
                :ref="docRef"
                v-model="documento"
                type="text"
                outlined
                dense
                label="Documento"
                hide-details
                :readonly="!objetoNoVacio(selectedTipoDoc) || nuevo == 0"
                v-if="selectedTipoDoc.id != 1"
                @blur="getCliente()"
                @keypress.native.enter="$refs[docRef].blur()"
              >
                <template v-slot:append-outer>
                  <v-btn small icon :disabled="!objetoNoVacio(selectedTipoDoc) || nuevo == 0" @click="dialogBuscarCliente = true"><v-icon class="ma-2" small color="success">fas fa-search</v-icon></v-btn>
                </template>
              </v-text-field>
              <template v-else>
                <v-text-field-integer
                  v-model="documento"
                  label="CUIT"
                  v-bind:properties="{
                    // 'validate-on-blur': true,
                    // rules: editar && cliente.tipo_doc == 1 ? [rules.required] : [],
                    readonly: !objetoNoVacio(selectedTipoDoc) || nuevo == 0,
                    // filled: !editar || cliente.tipo_doc != 1,
                    outlined: true,
                    dense: true
                  }"
                  v-bind:options="{
                    inputMask: '##-########-#',
                    outputMask: '###########',
                    empty: null
                  }"
                  @blur="getCliente()"
                />
                <v-btn small icon :disabled="!objetoNoVacio(selectedTipoDoc) || nuevo == 0" @click="dialogBuscarCliente = true"><v-icon class="ma-2" small color="success">fas fa-search</v-icon></v-btn>
              </template>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-text-field
                v-model="nombre"
                type="text"
                outlined
                dense
                label="Nombre"
                hide-details
                readonly
              >
                <template v-slot:append-outer v-if="empleado == 1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon color="info" v-on="on">
                        <v-icon class="mb-3">fas fa-user-tie</v-icon>
                      </v-btn>
                    </template>
                    <span>Esta persona pertenece al grupo Borigen Betzel</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Importe, Financiación y Tarjeta -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" :class="selectedTipoDoc.id == 1 ? 'mt-0' : 'mt-5'">
            <v-col cols="12" sm="2" md="2" class="py-0">
              <text-field-money
                v-model="importe"
                v-bind:properties="{
                  prefix: '$',
                  'hide-details': true,
                  label: 'Importe',
                  readonly: nuevo == 0
                }"
                @blur="getFinanciaciones()"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-autocomplete
                v-model="selectedFinanc"
                item-text="descripcion"
                label="Financiación"
                :items="financiaciones"
                return-object
                hide-details
                clearable
                outlined
                dense
                @change="setTarjeta()"
                :disabled="nuevo == 0"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-0">
              <v-text-field
                v-model="tarjetaNombre"
                type="text"
                outlined
                dense
                label="Tarjeta"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Nro. de Tarjeta, Cupón, Lote y Cod. de Autorización -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-6" v-if="checkLapos == 0 || nuevo == 0">
            <v-col cols="12" sm="3" md="3" class="py-0">
              <v-text-field-integer
                v-model="numTarjeta"
                label="Últimos 4 Tarj."
                v-bind:properties="{
                  readonly: selectedFinanc == null || selectedFinanc == undefined || Object.keys(selectedFinanc).length == 0 || nuevo == 0,
                  outlined: true,
                  // rules: [rules.tarjeta],
                  'validate-on-blur': true,
                  dense: true
                }"
                v-bind:options="{
                  inputMask: '####',
                  outputMask: '####',
                  empty: null
                }"
              />
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-0">
              <v-text-field
                v-model="cupon"
                type="text"
                outlined
                dense
                label="Cupón"
                hide-details
                :readonly="nuevo == 0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-0">
              <v-text-field
                v-model="lote"
                type="text"
                outlined
                dense
                label="Lote"
                hide-details
                :readonly="nuevo == 0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-0">
              <v-text-field
                v-model="codAutorizacion"
                type="text"
                outlined
                dense
                label="Cod. Aut."
                hide-details
                :readonly="nuevo == 0"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Cobran por lapos integrado o no, Terminales Integradas -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" :class="checkLapos == 0 ? 'mt-0' : 'mt-6'">
            <v-col cols="12" sm="3" md="3" class="py-0" v-if="checkLapos == 1">
              <v-autocomplete
                v-model="selectedTerminal"
                item-text="nombre"
                label="Terminal"
                :items="terminales"
                return-object
                hide-details
                clearable
                outlined
                dense
                :disabled="nuevo == 0"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-0" v-if="checkLapos == 1 && (comp_caja_id > 0 || compCajaId > 0)">
              <v-autocomplete
                v-model="selectedEstado"
                item-text="estado_nombre"
                label="Estado Cobro"
                :items="estadosCobro"
                return-object
                hide-details
                clearable
                outlined
                dense
                disabled
              >
                <template v-slot:append-outer>
                  <v-tooltip bottom v-if="!cadenaVacia(msjEstado)">
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="info" v-on="on">
                        <v-icon small>fas fa-exclamation</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ msjEstado }}</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-0">
              <v-checkbox
                v-model="checkLapos"
                label="Lapos Integrado"
                :true-value="1"
                :false-value="0"
                hide-details
                dense
                :disabled="(lapos_desintegrado == 0 && lapos_desintegrado == 1) || (lapos_desintegrado == 1 && lapos_desintegrado == 0) || nuevo == 0"
              ></v-checkbox>
            </v-col>
          </v-row>
          <!-- Loading Circular -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" v-if="loadingCircular" class="mt-6" justify="center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <h4 class="text-primary">{{ msjCobros }}</h4>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="warning" @click="activo = false" v-if="(comp_caja_id == 0 && compCajaId == 0) || nuevo == 0" :disabled="(comp_caja_id > 0 || compCajaId > 0) && checkLapos == 1 && selectedEstado.estado_id == 1" class="ma-2">Volver</v-btn>
          <BtnConfirmar
            @action="controlAnulacion()"
            color="error"
            icono="far fa-times-circle"
            :texto="`¿Esta seguro de anular la Extracción de Efectivo?`"
            nombre="Anular"
            clase="ma-2"
            v-if="comp_caja_id > 0 || compCajaId > 0"
          />
          <v-btn color="success" v-if="(comp_caja_id > 0 || compCajaId > 0) && checkLapos == 1 && objetoNoVacio(selectedEstado) && selectedEstado.estado_id != 2 && selectedEstado.estado_id != 4" @click="actualizarEstado()"><v-icon small class="ma-2">fas fa-sync</v-icon> Sincronizar</v-btn>
          <BtnConfirmar
            @action="guardar()"
            color="success"
            icono="fas fa-save"
            :texto="`¿Esta seguro de grabar la Extracción de Efectivo?`"
            nombre="Guardar"
            clase="ma-2"
            v-if="comp_caja_id == 0 && compCajaId == 0"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Buscar Cliente -->
    <BuscarCliente 
      :value="dialogBuscarCliente"
      @input="setModalClientes"
      @selected="setCliente"
    />
  </div>
</template>

<script>
// cambios por MMACORITTO: agregue la condicion (comp_caja_id > 0 || compCajaId > 0) && checkLapos == 1 && selectedEstado.estado_id == 1 para q no puedan salir sin sincronizar
import { format_money, objetoNoVacio, cadenaVacia, roundNumber } from '../../util/utils'
import TextFieldMoney from '../util/TextFieldMoney.vue'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import BuscarCliente from '../ventas/venta/BuscarCliente.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    }
  },
  components: {
    TextFieldMoney,
    BtnConfirmar,
    BuscarCliente
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalRetEfect', value)
      }
    },
    lapos_integrado: {
      get(){
        return this.datos.lapos_integrado
      }
    },
    lapos_desintegrado: {
      get(){
        return this.datos.lapos_desintegrado
      }
    },
    efectivo_disponible: {
      get(){
        return this.datos.efectivo_disponible
      }
    },
    sucursal_codigo: {
      get(){
        return this.datos.sucursal_codigo
      }
    },
    empresa_codigo: {
      get(){
        return this.datos.empresa_codigo
      }
    },
    canal_codigo: {
      get(){
        return this.datos.canal_codigo
      }
    },
    pto_vta: {
      get(){
        return this.datos.pto_vta
      }
    },
    comp_caja_id: {
      get(){
        return this.datos.comp_caja_id
      }
    },
    nuevo: {
      get(){
        return this.datos.nuevo
      }
    }
  },
  data() {
    return {
      format_money: format_money,
      objetoNoVacio: objetoNoVacio,
      cadenaVacia: cadenaVacia,
      documento: '',
      selectedTipoDoc: {},
      tiposDocumento: [],
      nombre: '',
      importe: 0,
      selectedFinanc: {},
      financiaciones: [],
      tarjetaNombre: '',
      checkLapos: 0,
      numTarjeta: '',
      cc_mask: '',
      cc_digits: '',
      cupon: '',
      lote: '',
      codAutorizacion: '',
      load: false,
      docRef: 'docRef',
      dialogBuscarCliente: false,
      codigo: 0,
      limiteExtraccion: 0,
      redondeo: 0,
      rules: {
        tarjeta: value => (value ? value.replace('-', '').length : 0) > 14 || 'Número no válido'
      },
      compCajaId: 0,
      selectedTerminal: {},
      terminales: [],
      loadingCircular: false,
      msjCobros: '',
      selectedEstado: {},
      estadosCobro: [],
      solCobroId: 0,
      anulando: 0,
      msjEstado: '',
      empleado: 0
    }
  },
  methods: {
    async initForm(){
      if (this.lapos_integrado == 1 && this.nuevo == 1) this.checkLapos = 1
      this.load = true
      let initPeticion = await this.$store.dispatch('caja/ini_extracciones', {pto_vta: this.pto_vta})
      this.load = false
      if (initPeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.message,
          color: 'error'
        })
        return
      }
      this.tiposDocumento = initPeticion.datos
      this.selectedTipoDoc = this.tiposDocumento.filter(element => element.id == 2)[0]
      this.limiteExtraccion = parseInt(initPeticion.limite_extraccion)
      this.redondeo = parseInt(initPeticion.redondeo)
      this.terminales = initPeticion.terminales
      this.estadosCobro = initPeticion.estados_cobro
      // asigno el estado como pendiente
      this.selectedEstado = this.estadosCobro.filter(element => element.estado_id == 1)[0]
      // me llega el id de comprobante caja?
      if (parseInt(this.comp_caja_id) > 0){
        // paso a buscar los datos de la extracción
        this.load = true
        let extraccionPeticion = await this.$store.dispatch('caja/get_extraccion', {
          comprobante_caja_id: this.comp_caja_id,
          empresa_codigo: this.empresa_codigo,
          canal: this.canal_codigo,
          sucursal_codigo: this.sucursal_codigo
        })
        this.load = false
        if (extraccionPeticion.exito == 0){
          this.$store.dispatch('show_snackbar', {
            text: extraccionPeticion.message,
            color: 'error'
          })
          return
        }
        if (extraccionPeticion.extraccion.length == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'No se encontró la información de la extracción solicitada.',
            color: 'info'
          })
          return
        }
        // lleno los campos que necesito, tipo de documento
        this.selectedTipoDoc = this.tiposDocumento.filter(element => element.id == extraccionPeticion.extraccion[0].tipo_documento)[0]
        // documento
        if (this.selectedTipoDoc.id == 1) this.documento = extraccionPeticion.extraccion[0].cuit
        else this.documento = extraccionPeticion.extraccion[0].documento
        // codigo del cliente
        this.codigo = extraccionPeticion.extraccion[0].cliente_codigo
        // nombre del cliente
        this.nombre = extraccionPeticion.extraccion[0].nombre
        // importe
        this.importe = roundNumber(extraccionPeticion.extraccion[0].importe, 2)
        // financiación
        if (extraccionPeticion.fp.length == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'No se encontró la información de la Financiación utilizada para esta extracción.',
            color: 'warning'
          })
          return
        }
        this.financiaciones.push(extraccionPeticion.fp[0])
        this.selectedFinanc = extraccionPeticion.fp[0]
        // tarjeta nombre
        this.tarjetaNombre = this.selectedFinanc.tarjeta_nombre
        // lapos integrado?
        this.checkLapos = extraccionPeticion.extraccion[0].fp_integrado
        // ultimos 4 de la tarjeta
        this.numTarjeta = extraccionPeticion.extraccion[0].numero_tarjeta
        // cupon
        this.cupon = extraccionPeticion.extraccion[0].cupon
        // lote
        this.lote = extraccionPeticion.extraccion[0].lote
        // codigo de autorizacion
        this.codAutorizacion = extraccionPeticion.extraccion[0].codigo_autorizacion
        // empleado?
        this.empleado = extraccionPeticion.extraccion[0].empleado
        // campos de lapos integrado
        if (this.checkLapos == 1){
          // id de la solicitud de cobro
          if (!cadenaVacia(extraccionPeticion.extraccion[0].solicitud_cobro_id) && extraccionPeticion.extraccion[0].solicitud_cobro_id != 0) this.solCobroId = extraccionPeticion.extraccion[0].solicitud_cobro_id
          // terminal
          if (!cadenaVacia(extraccionPeticion.extraccion[0].terminal)) this.selectedTerminal = this.terminales.filter(element => element.numero == extraccionPeticion.extraccion[0].terminal)[0]
          // estado de cobro, aqui cuidado, primero ver si está anulado o cobrado exitoso, para ellos controlo los estados padres
          let anuladosCorrectos = extraccionPeticion.extraccion.filter(element => element.estado_padre == 4)
          let cobradosCorrectos = extraccionPeticion.extraccion.filter(element => element.estado_padre == 2)
          let datosExtraccion = extraccionPeticion.extraccion.filter(element => !cadenaVacia(element.estado_cobro) && parseInt(element.estado_cobro) > 0)
          if (anuladosCorrectos.length == 0 && cobradosCorrectos == 0){
            if (datosExtraccion.length > 0) this.selectedEstado = this.estadosCobro.filter(element => element.estado_id == datosExtraccion[0].estado_cobro)[0]
            else{
              this.$store.dispatch('show_snackbar', {
                text: 'No se pudo determinar el estado actual del Cobro por Lapos Integrado.',
                color: 'warning'
              })
              return
            }
          }
          else{
            // está anulado o cobrado
            if (anuladosCorrectos.length > 0) this.selectedEstado = this.estadosCobro.filter(element => element.estado_id == 4)[0]
            if (anuladosCorrectos.length == 0 && cobradosCorrectos.length > 0) this.selectedEstado = this.estadosCobro.filter(element => element.estado_id == 2)[0]
          }
        }
      }
    },
    limpiarForm(){
      this.documento = ''
      this.selectedTipoDoc = {}
      this.tiposDocumento = []
      this.nombre = ''
      this.importe = 0
      this.selectedFinanc = {}
      this.financiaciones = []
      this.tarjetaNombre = ''
      this.checkLapos = 0
      this.numTarjeta = ''
      this.cc_mask = ''
      this.cc_digits = ''
      this.cupon = ''
      this.lote = ''
      this.codAutorizacion = ''
      this.load = false,
      this.dialogBuscarCliente = false
      this.codigo = 0
      this.limiteExtraccion = 0
      this.redondeo = 0
      this.compCajaId = 0
      this.selectedTerminal = {}
      this.terminales = []
      this.loadingCircular = false
      this.selectedEstado = {}
      this.estadosCobro = []
      this.solCobroId = 0
      this.msjEstado = ''
      this.anulando = 0
      this.empleado = 0
    },
    formatMask () {
      if (isNaN(this.selectedFinanc.cantidad_digitos)) return
      //FORMO LA MASCARA CON LA CANTIDAD DE DIGITOS DE LA TARJETA
      const digitos = parseInt(this.selectedFinanc.cantidad_digitos)
      for (let i = 0; i < digitos; i++) {
        this.cc_digits += '#'
      }
      switch (digitos) {
        case 14:
          // es diners
          this.cc_mask = '####-######-####'
          break
        case 15:
          // es amex
          this.cc_mask = '####-######-#####'
          break
        case 17:
          // es platino/titanio
          this.cc_mask = '######-######-#####'
          break
        case 19:
          // es maestro
          this.cc_mask = '#######-#####-#######'
          break
        default:
          let div = parseInt(digitos / 4)
          this.cc_mask = '####'
          for (let i = 0; i < div - 1; i++) {
            this.cc_mask += '-####'
          }
          if(digitos != div * 4) {
            for (let i = 0; i < div; i++) {
              this.cc_mask += '#'
            }
          }
          break
      }
    },
    controlAnulacion(){
      this.anulando = 1
      if (this.checkLapos == 1){
        this.actualizarEstado()
        // es un lapos integrado, paso a enviar las anulaciones antes de anular
        return
      }
      // no es lapos integrado, paso a anular directamente la extraccion
      this.anular()
    },
    async anular(){
      let comprobanteAnular = 0
      if (parseInt(this.comp_caja_id) > 0) comprobanteAnular = parseInt(this.comp_caja_id)
      if (parseInt(this.compCajaId) > 0) comprobanteAnular = parseInt(this.compCajaId)
      this.load = true
      let anularPeticion = await this.$store.dispatch('caja/anular_extraccion', {comprobante_caja_id: comprobanteAnular})
      this.load = false
      if (anularPeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: anularPeticion.message,
          color: 'error'
        })
        return
      }
      this.$store.dispatch('show_snackbar', {
        text: 'Anulación completada con éxito.',
        color: 'success'
      })
      this.$emit('retiroExitoso', {})
      this.activo = false
    },
    async getCliente(){
      if (this.nuevo == 0) return
      if (!objetoNoVacio(this.selectedTipoDoc)){
        this.selectedTipoDoc = this.tiposDocumento[0]
        this.documento = ''
        this.codigo = 0
        this.nombre = ''
        return
      }
      if (cadenaVacia(this.documento)){
        this.codigo = 0
        this.nombre = ''
        return
      }
      this.load = true
      let clientePeticion = await this.$store.dispatch('clientes/buscar_cliente', {
        nombre: '',
        apellido: '',
        documento: this.documento
      })
      this.load = false
      if (clientePeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: clientePeticion.message,
          color: 'error'
        })
        this.codigo = 0
        this.nombre = ''
        return
      }
      if (clientePeticion.size == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se encontraron Clientes que coincidan con los parámetros de búsqueda.',
          color: 'info'
        })
        this.codigo = 0
        this.nombre = ''
        return
      }
      if (clientePeticion.size > 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Se encontró más de una coincidencia. Por favor, pase a buscar el Cliente correcto.',
          color: 'info'
        })
        this.codigo = 0
        this.nombre = ''
        this.dialogBuscarCliente = true
        return
      }
      // encontré un solo cliente, paso a asignarlo
      this.setCliente(clientePeticion.data[0])
    },
    setCliente(value){
      // guardo el codigo del cliente
      this.codigo = value.codigo
      // obtengo el tipo de documento
      let existe = this.tiposDocumento.filter(element => element.id == value.tipo_doc)
      if (existe.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se puede determinar el tipo de Documento o el mismo se encuentra deshabilitado.',
          color: 'error'
        })
        return
      }
      this.selectedTipoDoc = existe[0]
      if (this.selectedTipoDoc.id == 1) this.documento = value.cuit
      else this.documento = value.num_doc
      this.nombre = `${value.apellido} ${value.nombre == null ? '' : value.nombre}`
      // 14-03-2024 por MMURILLO, indicamos si es emplado o no
      this.empleado = value.empleado
    },
    setModalClientes(value){
      this.dialogBuscarCliente = value
    },
    controlDNI(){
      if (this.selectedTipoDoc == null || this.selectedTipoDoc == undefined) return
      if (this.selectedTipoDoc.id != 1){
        this.codigo = 0
        this.nombre = ''
        this.documento = ''
      }
    },
    async getFinanciaciones(){
      if (this.nuevo == 0) return
      // control del importe
      if (cadenaVacia(this.importe) || this.importe == 0) return
      let msj = ''
      let error = 0
      if (roundNumber(this.importe, 2) % parseInt(this.redondeo) != 0){
        msj = `El importe de las extracciones deben ser múltiplos de $${this.redondeo}. Pruebe otro importe.`
        error = 1
      }
      if (roundNumber(this.importe, 2) > roundNumber(this.limiteExtraccion, 2)){
        msj = `El importe de las extracciones no puede ser mayor a $${this.limiteExtraccion}. Pruebe otro importe.`
        error = 1
      }
      if (roundNumber(this.importe, 2) > roundNumber(this.efectivo_disponible, 2)){
        msj = `El importe de las extracciones no puede ser mayor al efectivo disponible en caja ($${this.efectivo_disponible}). Pruebe otro importe.`
        error = 1
      }
      if (cadenaVacia(this.codigo) || this.codigo == 0){
        msj = 'No se detectaron datos correctos del Cliente. Revisar.'
        error = 1
      }
      if (error == 1){
        this.$store.dispatch('show_snackbar', {
          text: msj,
          color: 'warning'
        })
        this.selectedFinanc = {}
        this.financiaciones = []
        this.importe = 0
        return
      }
      this.financiaciones = []
      this.load = true
      let fpsPeticion = await this.$store.dispatch('caja/get_fp_extracciones', {
        importe: roundNumber(this.importe, 2),
        sucursal: this.sucursal_codigo,
        tipo_venta: 'ACCESORIOS',
        manual: 0,
        empresa: this.empresa_codigo,
        canal: this.canal_codigo,
        pto_vta: this.pto_vta,
        articulos: [],
        debito: 1,
        fp_cond_id: 0
      })
      this.load = false
      if (fpsPeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: fpsPeticion.message,
          color: 'error'
        })
        return
      }
      this.financiaciones = fpsPeticion.datos
    },
    setTarjeta(){
      if (!objetoNoVacio(this.selectedFinanc)) return
      this.tarjetaNombre = this.selectedFinanc.tarjeta_nombre
      this.formatMask()
    },
    validarGuardar(){
      if (cadenaVacia(this.codigo) || this.codigo == 0) return {valido: false, msj: 'Faltan datos del Cliente. Revisar.'}
      if (!objetoNoVacio(this.selectedTipoDoc)) return {valido: false, msj: 'Selecccione el Tipo de Documento.'}
      if (cadenaVacia(this.documento) || this.documento == 0) return {valido: false, msj: 'Documento o CUIT inválido.'}
      if (cadenaVacia(this.nombre)) return {valido: false, msj: 'Nombre de Cliente inválido.'}
      if (cadenaVacia(this.importe) || roundNumber(this.importe, 2) == 0) return {valido: false, msj: 'Importe inválido.'}
      if (!objetoNoVacio(this.selectedFinanc)) return {valido: false, msj: 'Selecccione la Financiación.'}
      if (cadenaVacia(this.tarjetaNombre)) return {valido: false, msj: 'Nombre de Tarjeta inválida. Revisar.'}
      if (this.checkLapos == 0 && (cadenaVacia(this.numTarjeta) || cadenaVacia(this.cupon) || cadenaVacia(this.lote) || cadenaVacia(this.codAutorizacion))) return {valido: false, msj: 'Debe completar Nro. de Tarjeta, Cupón, Lote y Cód.  de Autorización para continuar..'}
      return {valido: true, msj: 'OK'}
    },
    async guardar(){
      let valido = this.validarGuardar()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning'
        })
        return
      }
      // 07-03-2024 por MMURILLO, primero preguntamos cómo se encuentra el servicio de PAYWAY si es que van a cobrar con lapos integrado
      this.load = true
      if (this.checkLapos == 1){
        let statusPeticion = await this.$store.dispatch('laposIntegrado/get_payway_status', this.empresa_codigo)
        if (statusPeticion.exito == 0){
          this.load = false
          this.$store.dispatch('show_snackbar', {
            text: statusPeticion.message,
            color: 'error'
          })
          return
        }
      }
      // terminal utilizada
      let terminal = 0
      if (objetoNoVacio(this.selectedTerminal)) terminal = this.selectedTerminal.numero
      // envio a guardar
      let savePeticion = await this.$store.dispatch('caja/guardar_extraccion', {
        fp: this.selectedFinanc,
        cliente_codigo: this.codigo,
        importe: roundNumber(this.importe, 2),
        nro_tarjeta: cadenaVacia(this.numTarjeta) ? null : this.numTarjeta,
        cupon: cadenaVacia(this.cupon) ? null : this.cupon,
        lote: cadenaVacia(this.lote) ? null : this.lote,
        cod_autorizacion: cadenaVacia(this.codAutorizacion) ? null : this.codAutorizacion,
        lapos_integrado: this.checkLapos,
        pto_vta: this.pto_vta,
        user: this.$store.state.username,
        terminal: terminal
      })
      this.load = false
      if (savePeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: savePeticion.message,
          color: 'error'
        })
        return
      }
      if (this.checkLapos == 1){
        // asigno el id de la caja
        this.compCajaId = savePeticion.comp_caja_id
        this.solCobroId = savePeticion.sol_cobro_id
        if (cadenaVacia(savePeticion.sol_cobro_id) || savePeticion.sol_cobro_id == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo determinar el ID de la Solicitud de Cobro.',
            color: 'error'
          })
          this.$emit('retiroExitoso', savePeticion)
          this.activo = false
          return
        }
        this.enviarLaposIntegrado(savePeticion, 1)
        return
      }
      // exito al guardar la extracción de efectivo
      this.$store.dispatch('show_snackbar', {
        text: 'La extracción de Efectivo fué grabada exitosamente',
        color: 'success'
      })
      this.$emit('retiroExitoso', savePeticion)
      this.activo = false
    },
    async enviarLaposIntegrado(res, opcion){
      // paso a realizar los envios a lapos integrado, ya tengo el ID de solicitud de cobro
      if (opcion == 1){
        this.msjCobros = 'Enviando Cobros a Lapos Integrado...'
        this.loadingCircular = true
        let enviarPeticion = await this.$store.dispatch('laposIntegrado/enviar_pago', res.sol_cobro_id)
        this.loadingCircular = false
        if (enviarPeticion.exito == 0){
          this.$store.dispatch('show_snackbar', {
            text: savePeticion.message,
            color: 'error'
          })
          return
        }
        this.$store.dispatch('show_snackbar', {
          text: 'Extracción Enviada con éxito.',
          color: 'success'
        })
      }else{
        this.msjCobros = 'Enviando Anulaciones a Lapos Integrado...'
        this.loadingCircular = true
        let enviarPeticion = {}
        if (this.selectedEstado.estado_id == 1) enviarPeticion = await this.$store.dispatch('laposIntegrado/cancelar_pago', res.sol_cobro_id)
        if (this.selectedEstado.estado_id == 5) enviarPeticion = await this.$store.dispatch('laposIntegrado/set_anular_oracle', res.sol_cobro_id)
        if (this.selectedEstado.enviar_anular == 2) enviarPeticion = await this.$store.dispatch('laposIntegrado/enviar_anular', res.sol_cobro_id)
        this.loadingCircular = false
        if (enviarPeticion.exito == 0){
          this.$store.dispatch('show_snackbar', {
            text: savePeticion.message,
            color: 'error'
          })
          return
        }
        if (this.selectedEstado.estado_id == 2){
          this.$store.dispatch('show_snackbar', {
            text: 'Solicitud de Anulación enviada con éxito.',
            color: 'success'
          })
          return
        }
        // si estuvo pendiente o con error, paso a anular directamente
        this.anular()
      }
    },
    async actualizarEstado(){
      // primero veo como se encuentra la api de PAYWAY
      this.load = true
      let statusPeticion = await this.$store.dispatch('laposIntegrado/get_payway_status', this.empresa_codigo)
      if (statusPeticion.exito == 0){
        this.load = false
        this.$store.dispatch('show_snackbar', {
          text: statusPeticion.message,
          color: 'error'
        })
        return
      }
      let updatePeticion = await this.$store.dispatch('laposIntegrado/actualizar', this.solCobroId)
      if (updatePeticion.exito == 0){
        this.load = false
        this.$store.dispatch('show_snackbar', {
          text: updatePeticion.message,
          color: 'error'
        })
        return
      }
      // obtengo el estado actual de la extracción
      let estadoPeticion = await this.$store.dispatch('laposIntegrado/get_estado', this.solCobroId)
      this.load = false
      if (estadoPeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: estadoPeticion.message,
          color: 'error'
        })
        return
      }
      if (estadoPeticion.estado_actual.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar el estado actual de la extracción.',
          color: 'error'
        })
        return
      }
      // asigno el estado actual
      this.selectedEstado = this.estadosCobro.filter(element => element.estado_id == estadoPeticion.estado_actual[0].estado_id)[0]
      this.msjEstado = estadoPeticion.estado_actual[0].error_mensaje
      // controlo el estado
      if ((this.selectedEstado.estado_id == 1 || this.selectedEstado.estado_id == 5) && this.anulando == 0){
        // hago el envio nuevamente del cobro
        this.enviarLaposIntegrado({sol_cobro_id: this.solCobroId}, 1)
        return
      }
      if ((this.selectedEstado.estado_id == 2 || this.selectedEstado.estado_id == 5 || this.selectedEstado.estado_id == 1) && this.anulando == 1){
        this.enviarLaposIntegrado({sol_cobro_id: this.solCobroId}, 2)
        return
      }
      // controlo el exito
      if (this.anulando == 1 && this.selectedEstado.estado_id == 4){
        this.anular()
        return
      }
      if (this.anulando == 0 && this.selectedEstado.estado_id == 2){
        this.$store.dispatch('show_snackbar', {
          text: 'La extracción de efectivo se realizó exitosamente.',
          color: 'success'
        })
        this.$emit('retiroExitoso', {})
        this.activo = false
        return
      }
      // no se llegó al estado esperado
      this.$store.dispatch('show_snackbar', {
        text: 'La actualización finalizó exitosamente pero el estado actual no es el deseado.',
        color: 'info'
      })
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
      else this.initForm()
    },
    checkLapos: function(){
      if (this.nuevo == 1){
        // limpio los campos de tarjeta
        this.numTarjeta = ''
        this.cupon = ''
        this.lote = ''
        this.codAutorizacion = ''
        this.selectedTerminal = {}
      }
    },
    selectedTipoDoc: function(){
      if (this.selectedTipoDoc == null || this.selectedTipoDoc == undefined) this.selectedTipoDoc = {}
      if (Object.keys(this.selectedTipoDoc).length == 0) this.documento = ''
    },
    selectedFinanc: function(){
      if (this.selectedFinanc == null || this.selectedFinanc == undefined) this.selectedFinanc = {}
      if (Object.keys(this.selectedFinanc).length == 0){
        this.numTarjeta = ''
        this.cc_mask = ''
        this.cc_digits = ''
        this.tarjetaNombre = ''
      }
    }
  },
}
</script>

<style>

</style>