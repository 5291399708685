<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title style="word-break: normal;">
        Cerrar Caja
        <div v-if="local_nombre != ''" class="body-1 mt-1 ml-sm-3 ml-0">
          {{ local_nombre }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-6" :class="ajuste ? 'pb-3' : 'pb-5'">
        <v-row>
          <v-col cols="6" sm="4">
            <v-text-field-money
              v-model="efectivo"
              label="Efectivo"
              oncopy="return false"
              oncut="return false"
              v-bind:properties="{
                prefix: '$',
                type: 'tel',
                'hide-details': true,
                outlined: true,
                readonly: true,
                filled: true,
                dense: true
              }"
              v-bind:options="{
                locale: 'es-AR',
                precision: 2,
                empty: null
              }"
            />
          </v-col>
          <v-col cols="6" sm="4">
            <text-field-money
              v-model="arqueo"
              label="Efectivo arqueo"
              onpaste="return false"
              v-bind:properties="{
                prefix: '$',
                'hide-details': true
              }"
            />
          </v-col>
          <v-col v-if="ajuste" cols="12" sm="4" class="d-flex justify-sm-start justify-end align-center py-0 error--text body-1 font-weight-bold">
            {{ faltante ? 'Faltante' : 'Sobrante' }}
          </v-col>
          <v-col v-if="ajuste" cols="12">
            <v-form ref="obsAjuste">
              <v-textarea
                v-model.trim="observacion"
                label="Motivo ajuste"
                rows="2"
                :counter="50"
                :rules="[rules.required, rules.max]"
                validate-on-blur
                auto-grow
                outlined
                dense
              ></v-textarea>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4" :class="ajuste ? 'pt-0' : 'pt-2'">
        <v-btn
          color="error"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="ml-2"
          color="success"
          :disabled="arqueo == null"
          :loading="load"
          @click="controlCerrarCaja()"
        >
          <v-icon small left>fas fa-cash-register</v-icon>
          Cerrar Caja
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format_money } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import TextFieldMoney from '../util/TextFieldMoney.vue'

export default {
  data () {
    return {
      load: false,
      ajuste: false,
      faltante: false,
      arqueo: null,
      diferencia: null,
      observacion: '',
      rules: {
        required: value => !!value || 'Ingrese un motivo',
        max: value => value.length <= 50 || 'Máximo 50 caracteres'
      }
    }
  },
  props: {
    value: Boolean,
    efectivo: Number,
    tarjeta: Number,
    total: Number,
    comp_caja: Array,
    integrado: Number,
    cj_numero: Number,
    local_nombre: {
      type: String,
      default: ''
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    TextFieldMoney
  },
  watch: {
    dialog (val) {
      if (val) this.arqueo = null
    },
    arqueo () {
      if (this.arqueo == null) {
        this.ajuste = false
        this.faltante = false
        this.diferencia = null
        this.observacion = ''
        return
      }
      this.diferencia = parseFloat(this.efectivo) - parseFloat(this.arqueo)

      if (parseFloat(this.efectivo) > parseFloat(this.arqueo)) {
        this.ajuste = true
        this.faltante = true
      } else if (parseFloat(this.efectivo) < parseFloat(this.arqueo)) {
        this.ajuste = true
        this.faltante = false
        this.diferencia *= -1
      } else {
        this.ajuste = false
        this.observacion = ''
      }
    }
  },
  methods: {
    async controlCerrarCaja () {
      if (this.integrado == 1) {
        this.load = true
        let consulta = await this.$store.dispatch('laposIntegrado/update_caja', this.cj_numero)
        this.load = false

        if (consulta.exito != 1) {
          this.dialog = false
          return this.$swal.fire({
            icon: 'error',
            title: consulta.message
          })
        }
      }
      if (this.ajuste) {
        if (!this.$refs.obsAjuste.validate()) return
        await this.$swal.fire({
          icon: 'question',
          title: 'Confirmar Cierre de Caja',
          html: `¿Esta seguro de cerrar caja? Se esta por grabar un ${this.faltante ? 'faltante' : 'sobrante'} de <strong>${format_money(this.diferencia)}</strong>`,
          confirmButtonText: 'Sí',
          cancelButtonText: 'No',
          showCancelButton: true,
          focusCancel: true
        })
          .then((res) => {
            if (res.isConfirmed) this.cerrarCaja()
          })
      } else {
        this.cerrarCaja()
      }
    },
    async cerrarCaja () {
      // valido los comprobantes
      for (const comprobante of this.comp_caja) {
        let total = parseFloat(comprobante.efectivo) + parseFloat(comprobante.tarjeta)
        if (parseFloat(comprobante.importe).toFixed(2) != total.toFixed(2)) {
          this.dialog = false
          return this.$swal.fire({
            icon: 'error',
            title: 'Error al cerrar caja',
            text: 'Existen comprobantes que no coinciden en sus importes: ' + comprobante.numero
          })
        }
      }
      
      this.load = true
      let consulta = await this.$store.dispatch('caja/cerrar', {
        caja_jerarquia: this.cj_numero,
        total_efectivo: parseFloat(this.efectivo.toFixed(2)),
        total_tarjeta: parseFloat(this.tarjeta.toFixed(2)),
        ajuste: parseFloat(this.arqueo),
        motivo_ajuste: this.observacion,
        total_importe: parseFloat(this.total.toFixed(2)),
        cantidad_reg: this.comp_caja.length
      })
      this.load = false

      if (consulta.resultado == 1) {
        this.$emit('cerrada')
        this.$swal.fire({
          icon: 'success',
          title: 'Se cerró la caja'
        })
      } else if (consulta.resultado == 2) {
        this.$emit('diferencia')
        this.$swal.fire({
          icon: 'warning',
          title: 'Existen diferencia en el efectivo. Se recargarán las operaciones y vuelva a intentar Cerrar Caja'
        })
      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'Error al cerrar caja',
          text: consulta.message
        })
      }
      this.dialog = false
    }
  }
}
</script>