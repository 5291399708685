<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title style="word-break: normal;">
        Comprobantes {{ comp_id != null ? `- Nº ${comp_id}` : '' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-form ref="form">
          <v-row>
            <!-- TIPO COMPROBANTE -->
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-autocomplete
                v-model="comprobante"
                label="Concepto"
                item-text="nombre"
                :items="conceptos"
                :rules="[rules.required]"
                :readonly="comp_id != null"
                :filled="comp_id != null"
                validate-on-blur
                return-object
                outlined
                dense
                @change="obtenerDatos()"
              ></v-autocomplete>
            </v-col>
            <v-col
              v-if="comprobante.tipifica == 1" 
              cols="12" sm="6" class="py-0"
            >
              <v-autocomplete
                v-model="tipificacion"
                label="Tipificación"
                item-text="descripcion"
                item-value="tipificacion_id"
                :items="tipificaciones"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col
              v-if="comprobante.transfiere == 1"
              cols="12" sm="6" md="4" class="py-0"
            >
              <v-autocomplete
                v-model="caja_asociada"
                label="Caja local"
                item-text="nombre"
                item-value="numero"
                :items="cajas_asociadas"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <!-- REFERENCIA / IMPORTE -->
            <v-col
              v-if="comprobante.gasto == 1"
              cols="6" sm="3" class="py-0"
            >
              <FechaPicker
                v-model="fecha"
                :fecha_maxima="moment(new Date()).format('DD/MM/YYYY')"
                label="Fecha"
              />
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model.trim="referencia"
                label="Referencia"
                :counter="50"
                :rules="[rules.max]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              v-if="comprobante.gasto == null"
              cols="6" sm="4" md="3" class="py-0"
            >
              <v-text-field
                v-model.trim="num_comp"
                label="Nº Comprobante"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <text-field-money
                v-model.trim="importe"
                label="Importe"
                v-bind:properties="{
                  prefix: '$',
                  rules: [rules.required],
                  'validate-on-blur': true,
                  readonly: ca_estado == 2,
                  filled: ca_estado == 2
                }"
              />
            </v-col>
            <!-- Tipos Comprobantes / Gasto Emision / Numero -->
            <template v-if="comprobante.gasto == 1">
              <v-col cols="12" sm="6" md="5" class="py-0">
                <v-autocomplete
                  v-model="tipo_comp"
                  label="Tipo Comprobante"
                  item-text="nombre"
                  item-value="id"
                  :items="tipos_gastos.filter(tc => tc.inhabilitado != 1)"
                  :rules="[rules.required]"
                  validate-on-blur
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="3" md="2" class="py-0">
                <v-text-field
                  v-model.trim="emision"
                  label="Emisión"
                  :rules="[rules.required]"
                  validate-on-blur
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="2" class="py-0">
                <v-text-field
                  v-model.trim="numero"
                  label="Numero"
                  :rules="[rules.required]"
                  validate-on-blur
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="2" class="py-0">
                <v-checkbox
                  v-model="original"
                  label="Original"
                  :true-value="1"
                  :false-value="null"
                  dense
                ></v-checkbox>
              </v-col>
              <!-- Empresa -->
              <v-col cols="8" sm="4" md="3" class="d-flex py-0">
                <v-text-field
                  v-model.trim="cuit"
                  label="CUIT"
                  :rules="[rules.required]"
                  validate-on-blur
                  outlined
                  dense
                  @blur="buscarEmpresa()"
                ></v-text-field>
                <v-btn
                  color="success"
                  class="ml-2"
                  small
                  icon
                  @click="dialog_empresa = true"
                >
                  <v-icon>fas fa-plus</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  v-model="razon_social"
                  label="Razón Social"
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          clase="ml-2"
          :texto="`¿Esta seguro de grabar <strong>${comprobante.nombre}</strong> por <strong>${format_money(importe)}</strong>?`"
          :disabled="comprobante.numero == null || importe == null"
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
    <!-- componentes -->
    <NuevaEmpresaGasto
      v-model="dialog_empresa"
      @nueva="nuevaEmpresa"
    />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { format_money } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import FechaPicker from '../util/FechaPicker'
import NuevaEmpresaGasto from './NuevaEmpresaGasto'
import TextFieldMoney from '../util/TextFieldMoney.vue'
import moment from 'moment'

export default {
  data () {
    return {
      moment: moment,
      format_money: format_money,
      dialog_empresa: false,
      load: false,
      comprobante: {},
      trans_id: null,
      tipificacion: null,
      caja_asociada: null,
      fecha: moment(new Date()).format('DD/MM/YYYY'),
      referencia: null,
      num_comp: null,
      importe: null,
      tipo_comp: null,
      emision: null,
      numero: null,
      original: 1,
      cuit: null,
      empresa_id: null,
      razon_social: null,
      conceptos: [],
      tipificaciones: [],
      cajas_asociadas: [],
      rules: {
        required: value => !!value || 'Campo requerido',
        max: value => (value ? value.length : 0) <= 50 || 'Máximo 50 caracteres'
      }
    }
  },
  props: {
    value: Boolean,
    cj_numero: Number,
    ca_numero: Number,
    ca_estado: Number,
    ca_nivel: Number,
    comp_id: Number
  },
  computed: {
    ...mapState('controlCaja', ['tipos_gastos']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    FechaPicker,
    NuevaEmpresaGasto,
    TextFieldMoney
  },
  watch: {
    async dialog (val) {
      if (val) {
        await new Promise(resolve => setTimeout(resolve, 1))
        this.$store.state.loading = true
        let respuesta = await this.$store.dispatch('caja/get_coceptos', this.ca_nivel)
        this.$store.state.loading = false

        if (respuesta.exito == 1) {
          this.conceptos = respuesta.conceptos
        } else {
          this.$store.dispatch('show_snackbar', {
            text: respuesta.message,
            color: 'error'
          })
        }
        if (this.comp_id != null) this.getTransaccion()
      } else {
        this.comprobante = {}
        this.conceptos = []
        this.limpiar()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        if (parseFloat(this.importe.toFixed(2)) <= 0) {
          return this.$store.dispatch('show_snackbar', {
            text: 'El importe debe ser mayor a 0',
            color: 'orange'
          })
        }
        if (this.comprobante.gasto == 1 && this.fecha == null) {
          return this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una fecha',
            color: 'orange'
          })
        }

        // si es un deposito, paso los datos necesarios para el componenete Deposito
        if (this.comprobante.numero == 9 || this.comprobante.numero == 126) {
          this.dialog = false
          this.$emit('deposito', {
            concepto: this.comprobante.numero,
            referencia: this.referencia,
            num_comp: this.num_comp,
            importe: parseFloat(this.importe.toFixed(2)),
            comp_id: this.comp_id
          })
          return
        }

        let accion = this.comp_id == null ? 'nuevo' : 'editar'

        this.load = true
        let resultado = await this.$store.dispatch(`caja/${accion}_comprobante`, {
          caja_numero: this.ca_numero,
          comprobanteid: this.comprobante.numero,
          importe: parseFloat(this.importe.toFixed(2)),
          numero_comprobante: this.num_comp,
          referencia: this.referencia,
          tipificacion: this.tipificacion,
          fecha: this.fecha,
          tipo_comprobante: this.tipo_comp,
          emision: this.emision,
          numero: this.numero,
          original: this.original,
          empresa_id: this.empresa_id,
          caja_jerarquia: this.cj_numero,
          caja_jerarquia_asoc: this.caja_asociada,
          total_efectivo: null,
          comprobante_id: this.comp_id,
          transaccion_id: this.trans_id
        })
        this.load = false

        if (resultado.exito == 1) {
          this.dialog = false
          this.$emit('exito_comprobante')
          this.$swal.fire({
            icon: 'success',
            title: resultado.message
          })
        } else {
          this.$swal.fire({
            icon: 'error',
            title: resultado.message
          })
        }

      }
    },
    async getTransaccion () {
      this.$store.state.loading = true
      let respuesta = await this.$store.dispatch('caja/get_transaccion', this.comp_id)
      this.$store.state.loading = false

      if (respuesta.exito == 1) {
        const trans = respuesta.transaccion
        this.comprobante = this.conceptos.find(co => co.numero == trans.tipo_comprobante)
        await this.obtenerDatos()
        this.trans_id = trans.id
        this.referencia = trans.referencia
        this.fecha = trans.fecha_comprobante
        this.original = trans.original
        this.num_comp = trans.numero_comprobante
        this.importe = trans.importe
        this.emision = trans.empresa_emision
        this.numero = trans.empresa_comp_numero
        this.tipificacion = trans.tipificacion
        this.tipo_comp = trans.empresa_tipo_factura
        if (trans.empresa_codigo != null) {
          this.cuit = trans.empresa_cuit
          await this.buscarEmpresa()
        }
      } else {
        this.dialog = false
        this.$swal.fire({
          icon: 'error',
          title: respuesta.message,
          text: `Comprobante Nº ${this.comp_id}`
        })
      }
    },
    async obtenerDatos () {
      this.getTipificacion()
      this.getCajasAsociadas()
      this.getTiposComprobante()
      this.limpiar()
    },
    async buscarEmpresa () {
      if (this.cuit == null || this.cuit == '') {
        this.cuit = null
        this.empresa_id = null
        this.razon_social = null
        return
      }
      this.$store.state.loading = true
      await this.$store.dispatch('controlCaja/get_gastos_empresas', { cuit: this.cuit, solo_uno: 1 })
        .then((res) => {
          this.empresa_id = res.data[0].id
          this.razon_social = res.data[0].nombre
        })
        .catch(error => {
          this.cuit = null
          this.empresa_id = null
          this.razon_social = null
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'info'
          })
        })
      this.$store.state.loading = false
    },
    async getTipificacion () {
      this.tipificaciones = []
      if (this.comprobante.tipifica != 1) return

      this.$store.state.loading = true
      let respuesta = await this.$store.dispatch('caja/get_tipificaciones', this.comprobante.numero)
      this.$store.state.loading = false

      if (respuesta.exito == 1) {
        this.tipificaciones = respuesta.tipificaciones
      } else {
        this.$store.dispatch('show_snackbar', {
          text: respuesta.message,
          color: 'error'
        })
      }
    },
    async getCajasAsociadas () {
      this.cajas_asociadas = []
      if (this.comprobante.transfiere != 1) return

      this.$store.state.loading = true
      let respuesta = await this.$store.dispatch('caja/get_cajas_asociadas', this.cj_numero)
      this.$store.state.loading = false

      if (respuesta.exito == 1) {
        this.cajas_asociadas = respuesta.cajas
      } else {
        this.$store.dispatch('show_snackbar', {
          text: respuesta.message,
          color: 'error'
        })
      }
    },
    async getTiposComprobante () {
      if (this.comprobante.gasto != 1) return
      this.$store.state.loading = true
      await this.$store.dispatch('controlCaja/get_tipos_gastos')
      this.$store.state.loading = false
    },
    nuevaEmpresa (cuit) {
      this.cuit = cuit
      this.buscarEmpresa()
    },
    limpiar () {
      this.trans_id = null
      this.tipificacion = null
      this.caja_asociada = null
      this.fecha = moment(new Date()).format('DD/MM/YYYY')
      this.referencia = null
      this.num_comp = null
      this.importe = null
      this.tipo_comp = null
      this.emision = null
      this.numero = null
      this.original = 1
      this.cuit = null
      this.empresa_id = null
      this.razon_social = null
      this.tipificaciones = []
      this.cajas_asociadas = []
      this.$refs.form.resetValidation()
    }
  }
}
</script>