<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title style="word-break: normal;">
        Abrir Caja
        <div v-if="local_nombre != ''" class="body-1 mt-1 ml-sm-3 ml-0">
          {{ local_nombre }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          :disabled="load"
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-6">
        <v-row>
          <v-col cols="6" sm="4">
            <FechaPicker
              v-model="fecha"
              label="Fecha"
              hide-details
              disabled
            />
          </v-col>
          <v-col cols="6" sm="4">
            <v-text-field-money
              v-model="transporte"
              label="Transporte"
              v-bind:properties="{
                prefix: '$',
                type: 'tel',
                'hide-details': true,
                outlined: true,
                readonly: true,
                filled: true,
                dense: true
              }"
              v-bind:options="{
                locale: 'es-AR',
                precision: 2,
                empty: null
              }"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="ml-2"
          color="success"
          :loading="load"
          @click="abrirCaja()"
        >
          <v-icon small left>fas fa-cash-register</v-icon>
          Abrir Caja
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FechaPicker from '../util/FechaPicker'
import moment from 'moment'

export default {
  data () {
    return {
      load: false,
      fecha: moment(new Date()).format('DD/MM/YYYY')
    }
  },
  props: {
    value: Boolean,
    cj_numero: Number,
    transporte: Number,
    local_nombre: {
      type: String,
      default: ''
    },
    permite_abrirla: {
      type: Object,
      default: function() {
        return {
          valor: 0,
          cj_numero: null
        }
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    FechaPicker
  },
  watch: {
    dialog (val) {
      if (val) this.fecha = moment(new Date()).format('DD/MM/YYYY')
    }
  },
  methods: {
    async abrirCaja () {
      let cj_numero = !this.cj_numero && this.permite_abrirla.valor == 1 && this.permite_abrirla.cj_numero ? this.permite_abrirla.cj_numero : this.cj_numero

      this.load = true
      let consulta = await this.$store.dispatch('caja/abrir', cj_numero)
      this.load = false
      
      if (consulta.resultado == 1) {
        this.$emit('abierta')
        this.$swal.fire({
          icon: 'success',
          title: 'Se abrió la caja del día'
        })
      } else {
        this.$swal.fire({
          icon: 'error',
          title: consulta.message
        })
      }
      this.dialog = false
    }
  }
}
</script>