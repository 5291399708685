<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="50%"
    >
      <v-card
        flat
      >
        <v-card-title>
          <v-icon class="ma-2">far fa-envelope</v-icon> Enviar Factura al Cliente
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false" title="Cerrar"><v-icon>fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <!-- Cliente y Nro de Comprobante -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="6" md="6" class="py-2">
              <v-text-field
                v-model="cliente"
                type="text"
                outlined
                dense
                label="Cliente"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-2">
              <v-text-field
                v-model="nro_comprobante"
                type="text"
                outlined
                dense
                label="N° Comprobante"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Factura -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" class="py-2">
              <v-text-field
                v-model="factura"
                type="text"
                outlined
                dense
                label="Factura"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Email -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" class="py-2">
              <v-text-field
                v-model="email"
                type="text"
                outlined
                dense
                label="Email"
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end mx-2">
          <v-btn color="error" class="ma-2" @click="activo = false">Volver</v-btn>
          <BtnConfirmar
            texto="¿Está seguro de enviar el Email para el correo ingresado?"
            @action="enviarFactura()"
            color="success"
            nombre="Enviar"
            icono="fas fa-share-square"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    BtnConfirmar,
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalEnvEmail', value)
      }
    },
    cliente: {
      get(){
        return this.datos.cliente
      }
    },
    nro_comprobante: {
      get(){
        return this.datos.nro_comprobante
      }
    },
    factura: {
      get(){
        return this.datos.factura
      }
    }
  },
  data() {
    return {
      email: ''
    }
  },
  methods: {
    initModal(){
      this.email = this.datos.email
    },
    clearModal(){
      this.email = ''
    },
    async enviarFactura(){
      if (this.email.toString().length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar el Email.',
          color: 'info',
        })
        return
      }
      this.$store.state.loading = true
      let envioPeticion = await this.$store.dispatch('caja/enviar_factura_cliente', {
        venta_id: this.nro_comprobante,
        email: this.email
      })
      this.$store.state.loading = false
      if (envioPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Email enviado con éxito.',
          color: 'success',
        })
      }else{
        this.$store.dispatch('show_snackbar', {
          text: envioPeticion.message,
          color: 'error',
        })
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == true) this.initModal()
      else this.clearModal()
    },
    email: function(){
      if (this.email == null || this.email == null) this.email = ''
    }
  },
}
</script>

<style>

</style>